import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import useMediaQuery from "../../hooks/useMediaQuery"

const ItConsulting = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "1.5rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Go from Quickbase App to Quickbase Infrastructure
            </h2>
            <p className="mx-auto" style={{ maxWidth: "825px" }}>
              Quickbase is more than a single application or several siloed
              tools. Get the most value by creating an ecosystem of connected
              applications.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label for="tab1" class="tabs__label mx-auto">
                Connect Your Entire Application Stack
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/5c767b6f54e293c38a2e08d2147ddc58b1692c9e-1920x1306.jpg"
                      alt="Connect Your Entire Application Stack"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Connect Your Entire Application Stack
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Build seamless data infrastructure by integrating tools
                      with Quickbase—ensuring smooth workflows, real-time
                      insights, and better decision-making.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label for="tab2" class="tabs__label mx-auto">
                Get Smarter Workflows
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Get Smarter Workflows</h3>
                    <p className="my-4 text-left-mobile">
                      Connect your Quickbase applications to create smarter,
                      automated workflows—freeing your team to focus on
                      high-impact tasks.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/51903f2a1a4d14beda1b3e09e02cd4d13e855a8e-1920x1280.jpg"
                      alt="Get Smarter Workflows"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label for="tab3" class="tabs__label mx-auto">
                Discover Actionable Insights
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/31c592a4379c99f8dbe6ee9a581a392d321f7ff7-1920x1440.jpg"
                      alt="Discover Actionable Insights"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Discover Actionable Insights
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Seamlessly transfer data between Quickbase apps and other
                      systems to gain customer insights and reporting for better
                      decision-making.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab4"
              />
              <label
                for="tab4"
                class="tabs__label mr-3 ml-auto mt-2 text-center"
              >
                Reduce Human Error
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Reduce Human Error</h3>
                    <p className="my-4 text-left-mobile">
                      Automate workflows within your Quickbase ecosystem to
                      eliminate manual errors, ensuring real-time, accurate data
                      organization-wide.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/c2442332d357aee8e8f223fe98b37be2a549db25-1920x1280.jpg"
                      alt=" Reduce Human Error"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab5"
              />
              <label
                for="tab5"
                class="tabs__label ml-3 mr-auto text-center mt-2"
              >
                Simplified Scalability
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/b283228466d88436251ef85f6973cda129caf863-1920x1280.jpg"
                      alt=" Simplified Scalability "
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Simplified Scalability{" "}
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Easily expand your Quickbase ecosystem by integrating new
                      applications into a well-connected, flexible
                      infrastructure that grows with your business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column pt-2">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Connect Your Entire Application Stack</h3>
                    <p className="my-4 ">
                      Build seamless data infrastructure by integrating tools
                      with Quickbase—ensuring smooth workflows, real-time
                      insights, and better decision-making.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/5c767b6f54e293c38a2e08d2147ddc58b1692c9e-1920x1306.jpg"
                      alt="Connect Your Entire Application Stack"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Get Smarter Workflows</h3>
                    <p className="my-4 ">
                      Connect your Quickbase applications to create smarter,
                      automated workflows—freeing your team to focus on
                      high-impact tasks.
                    </p>
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/51903f2a1a4d14beda1b3e09e02cd4d13e855a8e-1920x1280.jpg"
                      alt="Get Smarter Workflows"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Discover Actionable Insights</h3>
                    <p className="my-4 ">
                      Seamlessly transfer data between Quickbase apps and other
                      systems to gain customer insights and reporting for better
                      decision-making.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/31c592a4379c99f8dbe6ee9a581a392d321f7ff7-1920x1440.jpg"
                      alt=" Discover Actionable Insights"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Reduce Human Error</h3>
                    <p className="my-4 ">
                      Automate workflows within your Quickbase ecosystem to
                      eliminate manual errors, ensuring real-time, accurate data
                      organization-wide.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/c2442332d357aee8e8f223fe98b37be2a549db25-1920x1280.jpg"
                      alt=" Reduce Human Error"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Simplified Scalability</h3>
                    <p className="my-4 ">
                      Easily expand your Quickbase ecosystem by integrating new
                      applications into a well-connected, flexible
                      infrastructure that grows with your business.
                    </p>
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/b283228466d88436251ef85f6973cda129caf863-1920x1280.jpg"
                      alt=" Simplified Scalability"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default ItConsulting
